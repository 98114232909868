.cta-banner-container {
    background: $body_color;
    color: $secondary_color;
    padding: 15px 0;
    display: flex;
    min-height: 100px;

    .cta-banner {
        width: 100%;
        display: block;

        @include large-tablet {
            display: flex;
            align-items: center;
        }
    }

    strong {
        color: #fff;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin: 20px 0 10px;

        @include large-tablet {
            margin: 0;
        }
    }

    li {
        display: inline;
        position: relative;
        font-size: 3rem;
        color: $secondary-color;
        line-height: 3.6rem;

        &:last-of-type {
            border-right: 1px solid white;

            @include large-tablet {
                margin-left: 0;
                padding-right: 15px;
            }
        }


        &:first-of-type {
            margin-left: 0;

            @include large-tablet {
                margin-left: 15px;
            }
        }

        &:first-of-type:before {
            display: none;
        }

        &:last-of-type:before {
            display: none;
        }

        @include large-tablet {
            display: inline;
            float: right;
            position: relative;
        }
    }

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 15px;

        @include large-tablet {
            flex-direction: row;
        }
    }


    .cta-right,
    .cta-left {
        text-align: center;

        @include large-tablet {
            text-align: inherit;
            font-size: 1.1429rem;
        }

        a {

            @include large-tablet {
                font-size: 1.0714rem;
            }
        }

        p {
            color: inherit;
            display: none;
        }

        .button {
            padding: 1rem 0rem;
            width: 45%;
            line-height: 1.5rem;
            margin: 0;

            @include large-tablet {
                width: auto;
                padding: 1rem 1rem;
            }

            &.reverse {

                @include large-tablet {
                    padding: calc(1rem - 2px) calc(1rem - 2px);
                }
            }
        }
    }

    .cta-left {
        p {

            @include tablet {
                display: initial;
            }
        }
    }
}
