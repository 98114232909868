.resource-center {
    &.mock-resource-center {
        display: none;

        @include large-tablet {
            display: block;
            padding-bottom: 542px;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .tabber {
        @include gutter;
        padding-bottom: 15px;
        box-sizing: border-box;
        max-width: 1170px;
        margin: 0 auto 75px;

        @include large-tablet {
            padding-left: 0;
            padding-right: 0;
        }

        .flex-row {
            flex-wrap: wrap;

            @include large-tablet {
                flex-wrap: nowrap;
            }
        }

        .accordion {
            width: 100%;
            padding-top: 10px;

            &:not(:last-child) {
                .tab {
                    border-right: 2px solid #fff;
                }
            }

            &.open {
                .fa-angle-down {
                    transform: rotate(-180deg);
                }
            }
        }

        .tab {
            display: block;
            width: 100%;
            text-align: center;
            padding: 15px 10px;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            transition-duration: 0.3s;
            text-decoration: none;
            background-color: #262626;

            &:not(:last-child) {
                border-right: 1px solid #fff;
            }

            &:hover,
            &.active {
                background-color: $primary-color;
            }

            @include large-tablet {
                padding-top: 28px;
                padding-bottom: 28px;
                position: relative;


                &:hover,
                &.active {
                    background-color: transparent;
                    color: $primary-color;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 7px;
                        top: 0;
                        left: 0;
                        background-color: #262626;
                    }
                }
            }

            .flex {
                @include large-tablet {
                    justify-content: center;
                }
            }

            .count {
                font-weight: 400;

                &:before {
                    content: " - (";
                }

                &:after {
                    content: ")";
                }
            }

            .fa-angle-down {
                font-size: 24px;
                height: 24px;
                transition-duration: .3s;

                @include large-tablet {
                    display: none;
                }
            }
        }

        .tab-content-wrapper {
            position: relative;
        }

        .tab-content {
            @include large-tablet {
                height: auto !important;
                visibility: hidden;
                opacity: 0;
                width: 100%;
                transition-duration: 0.3s;
                z-index: -100;
                box-sizing: border-box;

                &.active {
                    display: block !important;
                }
            }

            .document-trigger {
                @include large-tablet {
                    display: none;
                }

                .fa {
                    position: relative;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 9px;
                    background-color: $primary-color;
                    color: #fff;
                    font-size: 12px;
                    text-decoration: none;

                    &:after {
                        content: "";
                    }

                    &::before {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%);
                        line-height: 19px;
                    }
                }

                .fa-minus {
                    display: none;
                }
            }

            .mobile-view {
                border: none;

                table,
                thead,
                tbody,
                tr,
                th,
                td {
                    display: block;
                }

                table {
                    width: 100%;
                    @include gutter;

                    &:nth-child(even) {
                        background-color: #efefef;
                    }

                    &.open {
                        .fa-plus {
                            display: none;
                        }

                        .fa-minus {
                            display: inline-block;
                        }
                    }

                    tr:not(:first-child) {
                        display: none;
                    }

                    tr:first-child {
                        padding-top: 17.5px;
                        padding-bottom: 17.5px;

                        th {
                            display: none;
                        }
                    }

                    tr:last-child {
                        th {
                            display: none;
                        }
                    }

                    tr {
                        padding-top: 9px;
                        padding-bottom: 15px;

                        &.display {
                            display: block;
                        }
                    }

                    th {
                        color: #262626;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: "Ubuntu", sans-serif;
                        padding-bottom: 6px;
                    }

                    td {
                        color: #262626;
                        font-size: 14px;

                        span:after {
                            content: ",";
                        }

                        span:last-of-type:after {
                            content: "";
                        }

                        .button:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            table {
                @include large-tablet {
                    width: 100%;
                }

                thead {
                    tr {
                        @include large-tablet {
                            &:nth-child(odd) {
                                background-color: transparent;
                            }
                        }
                    }
                }

                th {
                    @include large-tablet {
                        text-align: left;
                        padding-left: 15px;
                        padding-top: 35px;
                        padding-bottom: 35px;
                        box-sizing: border-box;
                        font-weight: 400;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                    }

                    &:last-child {
                        visibility: hidden;
                    }

                    &.contact-info {
                        visibility: visible;
                    }
                }

                th, td {

                    @include large-tablet {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &.country {
                            white-space: normal;
                        }

                        &:first-child {
                            width: 22.5%;
                            max-width: 320px;
                        }

                        &:nth-child(2) {
                            width: 22.5%;
                            max-width: 320px;
                        }

                        &:nth-child(3) {
                            width: 14.4%;
                            max-width: 140px;
                        }

                        &:nth-child(4) {
                            width: 12.6%;
                            max-width: 100px;
                        }

                        &:nth-child(5) {
                            width: 11.5%;
                            max-width: 105px;
                        }

                        &:last-child {
                            width: 250px;
                            text-align: right;
                            padding-right: 10px;
                        }

                        &.contact-header, &.contact-data {
                            width: 33%;
                            max-width: none;
                            text-align: left;
                        }
                    }
                }


                tr {
                    @include large-tablet {
                        &:nth-child(odd) {
                            background-color: #dedede;
                        }
                    }
                }

                td {
                    @include large-tablet {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-left: 15px;

                        span:not(:last-child) {
                            &:after {
                                content: ", ";
                            }
                        }
                    }

                    .button {
                        @include large-tablet {
                            margin: 5px;
                        }
                    }

                    .fa {
                        font-size: 28px;
                        vertical-align: middle;
                        margin-right: 5px;
                    }

                    &.tag-list {
                        span:after {
                            content: ",";
                        }

                        span:last-of-type:after {
                            content: "";
                        }
                    }
                }
            }

            &.active {
                visibility: visible;
                opacity: 1;
                z-index: 0;
            }
        }
    }
}

#download-modal {
    .modal-dialog {
        padding: 15px;
        background: #e9e9e9;
        background: -webkit-linear-gradient(top,#e9e9e9,#fff 115px);
        background: -o-linear-gradient(top,#e9e9e9 0,#fff 115px);
        background: linear-gradient(180deg,#e9e9e9 0,#fff 115px);
    }

    .modal-header {
        color: #b60014;
        font-size: 17px;
        font-weight: bold;
    }

    .acceptance .terms-conditions{
        height: 200px;
        overflow-y: scroll;
    }

    .terms-conditions p{
        line-height: 18px;
    }

    .modal-footer{
        .download-link-container{
            display:none;

            .button {
                float: right;
            }
        }

        .btn-default {
            margin-top: 12px;
            transition: all .5s ease;
        }
    }

    .terms-checkbox-container {
        margin-top: 15px;

        label {
            margin-left: 20px;
            margin-top: -20px;
        }
    }
}