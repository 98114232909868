.search-page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .85);
    z-index: 9999;


    p {
        font-size: 1.43rem;
        font-weight: 100;
        color: #fff;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 50px;
        color: #fff;
        font-size: 3rem;
        cursor: pointer;
        z-index: 99999;
        opacity: 1;
        text-shadow: none;
        font-weight: 100;
    }

    .search-row {
        padding-top: 120px;

        input[type="search"] {
            background: transparent;
            border: 0;
            border-bottom: 1px solid $category-bg-color-dark;
            width: 100%;
            font-size: 2rem;
            padding-bottom: 5px;
        }

        a.search {
            color: #fff;
            font-size: 2rem;
            position: absolute;
            right: 20px;
            top: 0;
        }
    }

    .search-info {
        padding-top: 80px;

        .right-bar {
            border-right: 1px solid grey;
        }

        .col-sm-5 {
            text-align: center;

            p {
                font-weight: 600;

                &.extra-margin {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
