.video-gallery {   
    padding-top: 10px;

    .latest-videos {
        margin-top: 35px;
        margin-bottom: 35px;

        label {
            margin-right: 12px;
        }
    }

    .select-wrapper {
        display: inline-block;
        position: relative;
        background-color: $primary-color;
        border-radius: 13px;
        z-index: 0;

        select {
            padding: 3px 12px;
            min-width: 136px;
            background-color: transparent;
            font-size: 14px;
            border-color: $primary-color;
            color: #fff;
            border-radius: 13px;
            z-index: 0;
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;

            option {
                background-color: $primary-color;
            }
        }

        select::-ms-expand {
            display: none;
        }

        &:before {
            content: '\f107';
            font-family: fontAwesome;
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
            z-index: -1;
        }
    }

    .video-card {
        @include gutter;
        width: 100%;
        margin-bottom: 40px;

        @include tablet {
            width: 50%;
        }
        @include large-tablet {
            width:33.33%;
        }
    }

    .video-list {
        .flex-row {
            flex-wrap: wrap;
        }
    }

    .video-wrapper {
        width: 100%;
        margin: 0 auto;
    }

    .title-wrapper {
        position: relative;
        padding-bottom: 33.4%;
        box-shadow: 0 2px 3px rgba(0, 0, 0, .16);

        .title {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            color: #95989A;
            @include gutter;
            padding-top: 22px;
            padding-bottom: 22px;
            font-size: 14px;
            font-weight: 300;
        }
    }
}