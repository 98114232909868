.search-results {
    h1 {
        margin-bottom: 35px;

        em {
            font-weight: 600;
            text-transform: none;
        }
    }

    p {
        color: #999;
        font-size: 1.05rem;
        line-height: 2.3rem;

        &.count {
            color: black;
            display: inline-block;
            margin-top: 25px;
            line-height: 1rem;
        }
    }

    .sf-search-results {
        hr:last-of-type {
            border: 0px;
        }
    }

    hr {
        border-color: #ddd;

        &.black {
            margin-top: 0px;
            border-color: #999;
        }
    }

    .search-image {
        /*border: 1px solid #999;*/
        min-height: 160px; //Temporary
        width: 100%; //Temporary
        padding-top: 10px;
    }

    .search-description {
    }

    .result-count-container {
        display: flex;
        align-items: center;
    }

    .cta-links {
        text-align: right;
        vertical-align: middle;
    }

    .accent {
        color: $accent-color;
        font-weight: 600;
    }

    .sf-media-body {
        strong {
            color: $accent-color;
        }
    }
}
