.latest-supported-games {
    background: linear-gradient(180deg,#e9e9e9 0,#fff 115px);
    .listing-title {
        margin-top: 30px;
        justify-content: space-between;
        align-items:center; 
        .h3 {
            font-size: 1.6rem;
            font-weight: 700;
            color:#262626;
        }

        a {
            padding: 1px 13px;
            margin-right: 25px;
            background: #515151;
            display: block;
            font-size: 14px;
            font-size: 1rem;
            border-radius: 21px;
            color: #fff;
            font-weight: 300;
            max-width: 100px;
            display: inline-block;
            min-width: 75px;
            text-align: center;

            &:hover {
                text-decoration: none;
                background-color: #b60014;
            }
        }
    }
   
}
