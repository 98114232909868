footer {
    background: $body_color;
    padding-top: 40px;

    h2,
    .h2,
    .h4,
    label {
        color: #fff;
    }

    h2,
    .h2 {
        font-size: 1.5rem;
        margin-bottom: 30px;
        margin-top: 10px;
    }

    h4,
    .h4 {
        font-size: 1rem;
    }

    p,
    a,
    li a {
        color: $body_color_dark;
        font-size: 0.93rem;
    }

    a:hover,
    li a:hover {
        color: $accent-color;
    }

    p {
        line-height: 2rem;
    }

    label {
        margin-right: 15px;
        text-align: left;
        font-size: 1.0714rem;
        width: 100%;
    }

    input[type="text"], input[type="email"] {
        background: $body_color;
        color: #fff;
        border: 1px solid #fff;
        padding: 12px 13px;
        border-radius: 5px 0 0 5px;
        font-weight: 200;
        margin-right: -4px;
        height: auto;

        @include laptop {
            width: 100%;
        }

        @include desktop {
            max-width: 236px;
        }
    }

    input[type=button],
    button[type=submit] {
        color: $accent_color;
        margin-left: 0;
        border: 1px solid #fff;
        border-radius: 0 5px 5px 0;
        padding: 12px 13px;
        transition: 1s ease all;
        line-height: unset;
    }

    input[type=button]:hover,
    button[type=submit]:hover {
        color: $body_color;
    }

    form {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;

        .form-group {
            align-items: center;
            margin-bottom: 0;
            display: flex;
            width: 100%;

            &.submit-button {
                width: auto;
                align-items: flex-end;
            }

            &.subscribe-box {
                flex-wrap: wrap;

                @include laptop {
                    flex-wrap: nowrap;
                }
            }
        }
    }

    .footer-break {
        width: 100%;
        margin: 40px auto;
        border-bottom: 1px solid #C2C2C2;
    }

    .newsletter-container {
        .flex-row {
            @include laptop {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .newsletter {
        text-align: left;
        width: 100%;
        @include gutter;

        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include laptop {
            max-width: 50%;
            @include gutter;
        }

        label {
            @include tablet {
                width: auto;
            }

            span {
                display: none;
            }
        }

        form {
            width: 100%;
            max-width: 325px;

            .subscribe-box {
                width: calc(100% - 60px);

                label {
                    margin: 0;
                }

                input {
                    width: 100%;
                    background-color: transparent;
                    padding: 20px 13px;
                    border-right: 0;
                    font-weight: normal;
                    color: #fff;
                }
            }

            .submit-button {
                width: 60px;

                input {
                    margin: 0;
                    padding: 10.5px 13px;
                    border-radius: 0 .25em .25em 0;
                    background-color: #FFFFFF;
                    color: $primary-color;
                    font-weight: 400;
                }
            }
        }

        .input-group {
            color: #fff;
        }
    }

    .footer-container {
        .flex-row {
            justify-content: space-between;

            & > div {
                @include gutter;
            }
        }

        .footer-description {
            display: none;

            @include laptop {
                display: block;
                width: 50%;
                max-width: 550px;
            }

            @include theme(".sfPageEditor") {
                width: 100%;
            }
        }

        .footer-nav-container {
            display: none;

            @include tablet {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            @include laptop {
                width: 50%;
            }

            .footer-nav {
                width: 33%;

                @include tablet {
                    display: block;
                }

                @include laptop {
                    width: auto;
                }

                @include theme(".sfPageEditor") {
                    width: 33%;
                }

                li {
                    padding: 12px 0;
                }

                ul {
                    margin-left: 0;
                    padding-left: 0;
                }
            }
        }
    }

    .site-footer__subfooter__copyright,
    .site-footer__subfooter__nav {
        display: block;
        @include gutter;
        width: 100%;

        @include tablet {
            width: auto;
        }

        ul li:not(:first-child) a {
            border-left: 1px solid #999;
            padding-left: 10px;
        }
    }

    .subfooter {
        @include tablet {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .flex-row {
            flex-wrap: wrap;
            align-items: center;

            @include tablet {
                width: calc(100% + 30px);
                justify-content: space-between;
                flex-wrap: nowrap;
            }
        }
    }

    .social-container,
    .copyright-nav {
        @include gutter;
    }

    .social-container {
        width: 250px;

        @include tablet {
            order: 2;
        }

        .social i,
        .social a.fa {
            font-size: 2rem;
            text-decoration: none;
        }

        .social {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    .copyright-nav {
        padding-top: 25px;
        padding-bottom: 25px;

        @include tablet {
            padding-top: 50px;
            padding-bottom: 50px;
            order: 1;
            width: 100%;
        }

        @include theme(".sfPageEditor") {
            width: auto;
        }

        .list-inline,
        p {
            margin: 0;
        }
    }
}
