.video-carousel {
    @include theme(".sfPageEditor"){
        display:block;
    }
    .item {
        padding-bottom: 30px;

        @include large-tablet {
            padding-top: 48px;
            padding-bottom: 90px;

        }

        .container {
            @include large-tablet {
                padding-left: 88px;
                padding-right: 88px;
            }
        }

        .flex-row {
            flex-wrap: wrap;

            @include large-tablet {
                flex-wrap: nowrap;
            }
        }

        h2,
        .h2 {
            color: white;
            margin-bottom: 20px;
            font-size: 1.7rem;
            font-weight: 600;
        }

        p {
            line-height: 2.2rem;
            font-size: 1.15rem;
            color: #fff;
        }

        .video-wrapper {
            width: 100%;
            max-width: 650px;
            margin: 0 auto;

            @include large-tablet {
                width: 50%;
            }
        }

        .video-description {
            @include large-tablet {
                width: 50%;
                padding-right: 30px;
                box-sizing: border-box;
            }
        }
    }



    &.owl-theme {

        .owl-nav {
            position: relative;

            @include large-tablet {
                position: absolute;
                width: 100%;
                top: 180px;
            }

            [class*='owl-'] {
                background: none;

                &:hover {
                    background: none;
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
                bottom: -10px;
            }

            .owl-prev {
                left: 0;
            }

            .owl-next {
                right: 0;
            }

            i {
                border: none;
                font-size: 2rem;
            }
        }

        .owl-dots {
            position: relative;
            counter-reset: slides-num;
            color: #fff;
            width: 60px;
            margin: 0 auto;
            top: -22px;

            @include large-tablet {
                position: absolute;
                right: 0;
                top: 220px;
            }

            /* Initialize counter. */
            &:after {
                content: counter(slides-num);
                /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
                display: inline-block;
                font-size: 16px;
                padding-left: 35px;
            }

            .owl-dot {
                position: absolute;
                display: inline-block;
                counter-increment: slides-num;
                /* Increment counter */
                margin-right: 5px;

                span {
                    display: none;
                }

                &.active {

                    &:before {
                        content: counter(slides-num) " of";
                        /* Use the same counter to get current item. */
                        width: 30px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}