.video {
    width: 100%;
    padding-bottom: 57%;
    position: relative;
    margin: 0 auto;


    .video-placeholder {
        background-color: grey;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;

        &:before {
            @include overlay;
            background-color: rgba(0, 0, 0, .5);
        }

        &.played {

            &:before,
            a {
                display: none;
            }
        }

        a {
            position: absolute;
            display: inline-block;
            height: 54px;
            width: 54px;
            border-radius: 54px;
            border: 2px solid #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition-duration: .3s;
            cursor: pointer;

            .fa-play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                padding-left: 5px;
                transition-duration: .3s;
            }

            &:hover {
                height: 64px;
                width: 64px;

                .fa-play {
                    font-size: 19px;
                }
            }
        }
    }
}