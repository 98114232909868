.game-listing.similar {
  padding-top:15px;
  background-color:$light_bg_color;
  
  .product-heading {
    text-align: center;

    .h3 {
      font-size: 1.64rem;
      font-weight: 700;
    }
    .h3:before,
    .h3:after {
      content: "";
      border-bottom: 1px solid $body_color;
      display: inline-block;
      min-width: 20%;
      margin-right: 10px;
      margin-bottom: 0.5rem;
    }

    .h3:after {
      margin-left: 10px;
      margin-right: 0;
    }
  }

}

@media only screen and (min-width: 768px) {
  .game-listing.similar {
    .product-heading {
      .h3:before,
      .h3:after {
        min-width:38%
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .game-listing.similar {
    .product-heading {
      .h3:before,
      .h3:after {
        min-width:40%
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .game-listing.similar {
    .product-heading {
      .h3:before,
      .h3:after {
        min-width:42%
      }
    }
  }
}
