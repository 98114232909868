.cabinet-details.grid {
    padding-bottom: 45px;
    .detail-header{
        margin-bottom:30px;
        .flex{
            justify-content: space-between;
            align-items: center;
        }
    }
    .h1{
        font-weight: 300;
        font-size: 40px;
    }
    .detail-image{
        text-align: center;
    }
    img {
        box-shadow: none;
        max-height: 625px;
    }
    .detail-cloned-games{
        margin-top:50px;
    }
}
.cabinet-details-breadcrumb{
    padding-top:50px;
}