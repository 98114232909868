.contact-us {
  
  h2,
  .h2 {
    font-size:1.43rem;
    font-weight:700;
    
  }
  
  .contact-locations {
    margin-top:40px;
    
    p {
      line-height:2rem; 
    }
    
    .contact-location-contact {
      margin-top:15px;
    }
  }
  .contact-listing {
    
    .tab-content {
    margin-bottom:75px;
    
    }
    
    h2,
    .h2 {
      color:$body_color;
      margin-bottom:20px;
    }
    
  }
  
  textarea {
    margin-top: 1rem;
  }
}

.dark.cta {
  background: $body_color;
  width:100%;
  color: #fff;
  padding:45px 0;
  text-align: center;
  margin:75px 15px;
  
  p {
    color:#fff;
    font-weight: 700;
  }
  
  .large {
    font-size:1.43rem;
  }
  
  a {
    color:#fff;
    text-decoration: underline;
  }
  a:hover {
    color:$hover-color;
  }
}

.login-cta{
    padding:25px;
}