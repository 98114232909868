.sort-by-wrapper{
    .flex-row{
        justify-content: space-between;
        align-content: center;
        margin:0 auto;
    }
    .sort-by{
        padding-top:15px;
        padding-bottom:15px;
        &:after{
            display: table;
            content: " ";
            clear: both;
        }
        .sort-by-label, .sort-by-select{
            float:left;
        }
    .sort-by-label{
        padding:2px 10px;
    }
    .sort-by-select{
        position: relative;
        select{
            padding:3px 45px 3px 10px;
            box-sizing: border-box;
            border:0;
            background-color:$primary-color;
            color:#fff;
            font-size:14px;
            border-radius: 13px;
            appearance: none;
            cursor:pointer;
            option{
                background-color:#fff;
                color:#262626;
            }
        }
        &:before{
            position: absolute;
            content:"\f107";
            font-family: fontAwesome;
            top:50%;
            transform: translateY(-50%);
            right:10px;
            color:#fff;
            padding-left:15px;
        }
    }

    }
}