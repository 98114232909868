.k-webkit {
    .kg-pager {
        &.k-pager-wrap {
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0;
            padding-top: 35px;
            padding-bottom: 35px;
            border-top: 1px solid #262626;

            .k-link {
                border: none;
            }
        }

        .k-pager-numbers {
            left: auto;
            margin: 0;

            &.k-state-expanded {
                padding: 0;
                top: 8px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%) translateY(-100%);

                .k-current-page {
                    margin: -2em 0;
                }
            }

            .k-current-page {
                margin: 0;

                .k-link {
                    padding: 0 9px;
                    background-color: $primary-color;
                    color: #fff;
                }
            }
        }

        .k-pager-numbers .k-state-selected,
        .k-pager-numbers > .k-link,
        .k-pager-numbers .k-link,
        .k-state-selected, .k-pager-numbers ul.pagination a {
            border: 1px solid $primary-color;
            padding: 5px 10px;
            text-align: center;
            border-radius: 0;

            @include large-tablet {
                width: 35px;
                border-radius: 2em;
                padding: 10px 0;
                line-height: 1em;
                float: none;
                min-width: 0;
                color: #262626;

                &:hover,
                &:focus {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    color: #fff;
                }
            }
        }

        .k-pager-numbers .k-state-selected, .k-pager-numbers ul.pagination > .active > a {
            background-color: $primary-color;
            border-color: $primary-color;
            color: #fff;
        }

        .k-pager-input,
        .k-pager-numbers li {
            @include large-tablet {
                margin: 0 8px;
            }
        }

        a.k-link.k-pager-nav.k-pager-first:after {
            @include phone {
                position: relative;
                content: "First";
                color: #262626;
                padding: 0 20px 0 5px;
            }
        }

        .k-i-arrow-end-left:before {
            content: "\f060";
            font-family: fontAwesome;
            color: #262626;
        }

        .k-i-arrow-60-left:before {
            content: "\f060";
            font-family: fontAwesome;
            color: #262626;
        }

        .k-i-arrow-60-right:before {
            font-family: fontAwesome;
            content: "\f061";
            color: #262626;
        }

        a.k-link.k-pager-nav.k-pager-last:before {
            @include phone {
                position: relative;
                content: "Last";
                color: #262626;
                padding: 0 5px 0 20px;
            }
        }

        .k-i-arrow-end-right:before {
            font-family: fontAwesome;
            content: "\f061";
            color: #262626;
        }

        .k-pager-info {
            display: none;
        }

        ul.pagination {
            & > li {
                float: none;

                > a {
                    height: 35px;
                    width: 35px;
                    border-radius: 100%;
                    display: inline-block;
                }
            }
        }

        
    }
}



.pagination {
    > li {
        > a {
            float: none;

            &, &:hover, &:focus {
                color: $primary-color!important;
            }
        }

        &.active {
            a, a:hover, a:focus {
                border-color: $primary-color!important;
                background-color: $primary-color!important;
                color: #fff;
            }
        }
    }
}

.search-pager {
    text-align: center;

    a {
        color: #262626;
    }

    > span {
        margin: 0 10px;
    }
}
