.login-controls {
    padding-top: 50px;
    padding-bottom: 50px;

    @include desktop {
        padding-top: 115px;
        padding-bottom: 115px;
    }

    .login-container {
        background: #fff;
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        padding: 0;

        &.flex-row {
            flex-wrap: wrap;

            @include laptop {
                flex-wrap: nowrap;
            }
        }

        input:not([type=checkbox]) {
            width: 100%;
            border: none;
            color: $body_color;
            border-bottom: 1px solid $body_color;
            font-weight: 500;
            border-radius: 0;
            box-shadow: none;
        }

        #password {
            margin-top: 40px;
        }

        .login-alt-actions {
            .flex-row {
                align-items: center;
                justify-content: space-between;
            }

            label {
                color: $secondary-color;
                font-weight: 300;
            }

            a {
                float: right;
            }
        }

        .login-form {
            max-width: 550px;

            @include tablet {
                padding: 0 85px;
            }

            h2,
            .h2 {
                font-weight: 300;
                color: $accent_color;
                font-size: 40px;
                margin-bottom: 50px;
            }

            h3,
            .h3 {
                font-weight: 300;
                color: $accent_color;
                font-size: 1.5rem;
                display: inline-block;
                margin-top: 5px;
            }

            .sf-registration-link {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                border-top: 1px solid rgba(#95989A,.17);
                padding-top: 20px;
                margin-top: 50px;

                @include tablet {
                    padding-top: 50px;
                }

                .h3 {
                    padding: 15px 0;
                }

                @include phone {
                    flex-wrap: nowrap;
                    justify-content: space-between;
                }
            }
        }

        .login-third-party {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            h2,
            .h2 {
                color: #fff;
                font-weight: 300;
                margin-bottom: 30px;
                font-size: 32px;

                @include tablet {
                    font-size: 40px;
                }
            }
        }

        .login-zendesk, .login-astea {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 250px;
            @include gutter;

            @include tablet {
                padding-left: 150px;
            }

            @include laptop {
                padding-left: 75px;
            }
        }

        .login-zendesk {
            background: #474747;
            border-bottom: 1px solid $secondary_color;
        }

        .login-astea {
            background: #313131;

            h2, a{
                display: none;
            }
        }

        .button {
            padding: 10px 35px;
        }
    }
}
