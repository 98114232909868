.dashboard-resource-center {
  background: $body_color;
  padding: 60px 0;

  h3,
  .h3 {
    color: #fff;
    font-weight: 700;
    font-size: 1.43rem;
    margin: 0.75em 1em 1em 0;
  }

  .tab-pane {
    .row-heading {
      background: $accent_color;
      display: none;
      width: 100%;
    }

    .alt-bg button {
      background-color: $body_color;
    }
    .accordian {
      color: #fff;
    }
    .resource {

      div {
        background: $light_bg_color;
      }
    }

  }
}


@media only screen and (min-width: 992px) {

  .dashboard-resource-center {

    .my-resource-link {
      text-align: right;
    }

    .tab-pane {

      .row-heading {
        display: flex;
        width: 100%;
      }

      .row-table-cell,
      .row-table-head {
        width: 12%;
        align-self: center;
        padding: 20px 30px;
        color: #fff;
      }

      .medium-cell {
        width: 17%;
      }

      .large-cell {
        width: 26%;
        align-self: center;
      }
      .button-cell {
        width: 20%;
        position: relative;
        min-height: 50px;

        a.button {
          right: 0;
          position: absolute;
          top: -5px;
        }
        a.button.light {
          right: 110px;
          position: absolute;
          border: none;

          :hover {
            border: none;
          }
        }
      

      }
        .resource {

          div {
            background: $body_color;
          }
        }

      .row-heading .button-cell {
        width: 25%;
      }

      .alt-bg,
      .alt-bg div {
        background-color: $body_color;
      }

      .resource {
        width: 100%;
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid $light_bg_color;
      }
      .resource:last-of-type {
        border-bottom: none;
      }

      .accordian[aria-selected="false"] ~ div:not(.resource-info-heading) {
        display: block!important;
      }

    }
    .accordian-icon {
      display: none;
    }
  }

}
