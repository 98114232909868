﻿$global_transition: 0s;

///.primary-color
$primary-color: #b60014;

///.primary-color-light
$primary-color-light: lighten($primary-color, 25%);

///.primary-color-dark
$primary-color-dark: darken($primary-color, 25%);

///.secondary-color
$secondary-color: #c9c9c9;

///.secondary-color-light
$secondary-color-light: lighten($secondary-color, 25%);

///.secondary-color-dark
$secondary-color-dark: darken($secondary-color, 25%);

///.accent-color
$accent-color: #b60014;

///.hover-color
$hover-color: #BB0018;

///.accent-color-light
$accent-color-light: lighten($accent-color, 25%);

///.accent-color-dark
$accent-color-dark: darken($accent-color, 25%);

///.category-bg-color
$category-bg-color: #b0b0b0;
$category-bg-color-dark: #515151;
$dark-accent-color: #95989A;
$table-alt-row: #DEDEDE;

///:root
$root_font-size: 14px;

///body, .body-text
$body_color: #262626;
$body_font-family: 'Ubuntu', sans-serif;
$body_font-size: 1rem;
$body_font-weight: normal;
$body_transition: $global_transition;
$body_color_light: #c9c9c9;
$body_color_dark: #999999;
$light_bg_color: #f2f2f2;

///.container
$container_margin: 0 auto;
$container_max-width: 1200px;
$container_padding-left: 15px;
$container_padding-right: 15px;
$container_width: 100%;

///.accent-font
$accent_font-family: 'Ubuntu', sans-serif;
$accent_font-style: normal;
$accent_font-weight: normal;
$accent_font-size: 1rem;
$accent_text-transform: none;

///img
$img_max-width: 100%;

//ul
$ul_list-style: inherit;
/*$ul_padding: inherit; <- This was causing nested uls to lose padding*/

$li_line-height:1.643em;

///p, .p, article, .article
$p_font-size: 1rem;
$p_line-height: 2;
$p_padding:15px 0 0;

//.em
$em_font-style: italic;

///a
$a_color: $primary-color;
$a_text-decoration: none;

///a:hover, a:focus
$a-hover_color: $secondary-color;
$a-hover_text-decoration: underline;

///h1, .h1
$h1_font-family: $accent_font-family;
$h1_font-weight: normal;
$h1_line-height: 1.3;
$h1_color: inherit;
$h1_margin-top: 10px;
$h1_margin-bottom: 10px;
$h1_font-size: 2.2857rem;
$h1_font-style: normal;
$h1_transition: .5s;

///h1 small, h1 .small, .h1 small, .h1 .small
$h1-small_font-size: 65%;
$h1-small_font-weight: normal;
$h1-small_line-height: 1;
$h1-small_color: #777777;

///h2, .h2
$h2_font-family: $accent_font-family;
$h2_font-weight: normal;
$h2_line-height: 1.3;
$h2_color: inherit;
$h2_margin-top: 20px;
$h2_margin-bottom: 0;
$h2_font-size: 2.25rem;
$h2_font-style: normal;
$h2_transition: .5s;



///h2 small, h2 .small, .h2 small, .h2 .small
$h2-small_font-size: 65%;
$h2-small_font-weight: normal;
$h2-small_line-height: 1;
$h2-small_color: #777777;

///h3, .h3
$h3_font-family: $accent_font-family;
$h3_font-weight: normal;
$h3_line-height: 1.3;
$h3_color: $primary-color;
$h3_margin-top: 0;
$h3_margin-bottom: 0;
$h3_font-size: 1.571rem;
$h3_font-style: normal;
$h3_transition: .5s;
$h3_text-transform:none;



///h3 small, h3 .small, .h3 small, .h3 .small
$h3-small_font-size: 65%;
$h3-small_font-weight: normal;
$h3-small_line-height: 1;
$h3-small_color: #777777;

///h4, .h4
$h4_font-family: $accent_font-family;
$h4_font-weight: normal;
$h4_line-height: 1.3;
$h4_color: $accent-color;
$h4_margin-top: 10px;
$h4_margin-bottom: 10px;
$h4_font-size: 1.125rem;
$h4_font-style: normal;
$h4_transition: .5s;



///h4 small, h4 .small, .h4 small, .h4 .small
$h4-small_font-size: 75%;
$h4-small_font-weight: normal;
$h4-small_line-height: 1;

///h5, .h5
$h5_font-family: $accent_font-family;
$h5_font-weight: normal;
$h5_line-height: 1.3;
$h5_color: inherit;
$h5_margin-top: 10px;
$h5_margin-bottom: 10px;
$h5_font-size: .875rem;
$h5_font-style: normal;
$h5_transition: .5s;



///h5 small, h5 .small, .h5 small, .h5 .small
$h5-small_font-size: 75%;
$h5-small_font-weight: normal;
$h5-small_line-height: 1;

///h6, .h6
$h6_font-family: $accent_font-family;
$h6_font-weight: normal;
$h6_line-height: 1.3;
$h6_color: inherit;
$h6_margin-top: 10px;
$h6_margin-bottom: 10px;
$h6_font-size: .75rem;
$h6_font-style: normal;
$h6_transition: .5s;



///h6 small, h6 .small, .h6 small, .h6 .small
$h6-small_font-size: 75%;
$h6-small_font-weight: normal;
$h6-small_line-height: 1;


///.btn
$btn_display: inline-block;
$btn_margin-bottom: 0;
$btn_font-weight: 700;
$btn_text-align: center;
$btn_vertical-align: middle;
$btn_touch-action: manipulation;
$btn_cursor: pointer;
$btn_background-image: none;
$btn_border: 2px solid #fff;
$btn_white-space: nowrap;
$btn_padding: 10px 20px;
$btn_font-size: 1.0769remrem;
$btn_line-height: 1.061;
$btn_border-radius: 0px;
$btn_user-select: none;
$btn_margin: 10px;
$btn_width: auto;
$btn_max-width: none;
$btn_text-decoration: none;
$btn_transition: .25s;
$btn_text-transform: uppercase;
$btn_font-family: $accent_font-family;
$btn_box-shadow: none;

///.btn:hover,
///.btn:focus, .btn.focus
$btn-hover_color: #333;
$btn-hover_text-decoration: none;

///.btn-primary
$btn-primary_color: #b60014;
$btn-primary_background-color: #fff;
$btn-primary_border-color: transparent;

///.btn-primary:focus, .btn-primary.focus
$btn-primary-focus_color: #fff;
$btn-primary-focus_background-color: #b60014;
$btn-primary-focus_border-color: transparent;

///.btn-primary:hover, .btn-primary.hover
$btn-primary-hover_color: #fff;
$btn-primary-hover_background-color: #b60014;
$btn-primary-hover_border-color: transparent;
$btn-primary-hover_text-decoration: none;


///.btn-primary:active, .btn-primary.active,
///.open > .btn-primary.dropdown-toggle
$btn-primary-active_color: #fff;
$btn-primary-active_background-color: #b60014;
$btn-primary-active_border-color: #292929;


///.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
///fieldset[disabled] .btn-primary:hover,
///fieldset[disabled] .btn-primary:focus,
///fieldset[disabled] .btn-primary.focus
$btn-primary-disabled_background-color: #fff;
$btn-primary-disabled_border-color: transparent;

///.btn-primary .badge
$btn-primary-badge_color: #fff;
$btn-primary-badge_background-color: #292929;

///.btn-secondary
$btn-secondary_color: #fff;
$btn-secondary_background-color: transparent;
$btn-secondary_border-color: #fff;

///.btn-secondary:focus, .btn-secondary.focus
$btn-secondary-focus_color: #333;
$btn-secondary-focus_background-color: #fff;
$btn-secondary-focus_border-color: #292929;

///.btn-secondary:hover
$btn-secondary-hover_color: #fff;
$btn-secondary-hover_background-color:inherit;
$btn-secondary-hover_border-color:inherit;

///.btn-secondary:active, .btn-secondary.active,
///.open > .btn-secondary.dropdown-toggle
$btn-secondary-active_color:inherit;
$btn-secondary-active_background-color:inherit;
$btn-secondary-active_border-color:inherit;


///.btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus,
///fieldset[disabled] .btn-secondary:hover,
///fieldset[disabled] .btn-secondary:focus,
///fieldset[disabled] .btn-secondary.focus
$btn-secondary-disabled_background-color: #fff;
$btn-secondary-disabled_border-color: #ccc;

///.btn-secondary .badge
$btn-secondary-badge_color: #fff;
$btn-secondary-badge_background-color: #292929;
