.team-listing {
    .listing-title{
        font-size:23px;
        line-height:26px;
        font-weight: 700;
        margin-top:100px;
    }
    .tile{
        padding:0;
    }
    .team-member-name{
        margin: 15px 15px 25px;
        color:$primary-color;
        font-weight:700;
        font-size:16px;
        line-height: 18px;
    }
}
