﻿.account-request-container{
    padding:15px;
    table{
        width: 100%;
        th{
            font-weight: bold;
            font-size: 1.25em;
            padding: 15px 0;
        }
    }
    .account-request{
        color:inherit;
        padding:15px;
        margin: 15px 0;
        border-top:1px solid;
       td{
           margin:15px;
       }
    }

    .button{
        cursor:pointer;
    }
}