.light-gradient-bg {
    background: linear-gradient(to bottom, #e9e9e9 0%, $light_bg_color 75px) !important;
}
.red-gradient-bg{
  background: linear-gradient(to bottom, #b60114 0%, #4d000a 100%) !important;
}
.dark-gradient-bg{
  background: linear-gradient(165deg, #333333 49%, #3b3b3b 50%, #3b3b3b 50%) !important;
}

.red-bg{
  background-color:$primary-color;
}