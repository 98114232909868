.featured-games {
    background-color: $light_bg_color;
    .product-listing {
        .listing-title {
            color: $body_color;
            font-size: 26px;
            line-height: 32px;
            margin: 60px 0 40px;
            text-align: center;
        }
    }
    .library-link{
        text-align: center;
        margin:60px auto;
    }
}

.game-listing {
    padding-top: 15px;
    padding-bottom: 100px;
}

.product-listing,
.taxon-listing,
.team-listing,
.my-games {
    img {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
    }
    .listing-title {
        font-weight: 300;
        margin-bottom: 20px;
        font-size: 1.6429rem;
    }
    .tiles.flex-row {
        justify-content: center;
        align-items: center;
        flex-wrap:wrap;
        @include at(1157){
            justify-content: left;
        }
    }
    .tile {
        padding: 15px;
        width: 262px;
        height: 207px;
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        background: #fff;
        overflow: hidden;
        margin: 15px 15px;
        background: #fff;
        box-sizing:border-box; 
        box-shadow: 0px 2px 3px rgba(0, 0, 0, .2);
        @include at(425) {
            width: calc(262px * .72);
            height: calc(207px * .72);
        }
        @include tablet{
            width: 262px;
            height: 207px;
        }
        img {
            align-self: center;
            width: 99% !important;
            max-width: unset !important;
        }
        &:hover .image {
            opacity: 0.3;
        }
        &:hover .tile-content {
            opacity: 1;
        }
    }
    .tile-name {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        @include phone {
            font-size: 12px;
            line-height: 20px;
        }
        @include tablet {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .tile-content {
        display: flex;
        background: $body_color;
        width: 100%;
        height: 100%;
        color: #fff;
        text-align: center;
        flex-direction: column;
        justify-content: space-around;
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        padding:10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .tile-action {
        margin: 0 auto;
        font-size: 15px;
        line-height: 1em;
        padding: 15px 30px;
        @include phone {
            font-size: 12px;
            padding:12px 27px;
        }
        @include tablet {
            font-size: 15px;
            padding:15px 30px;
        }
    }
    .tile-add a {
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid white;
        padding-bottom: 5px;
        transition: 0.5s all ease;
    }
    .tile-add a:hover {
        text-decoration: none;
        color: $hover_color;
        border-bottom: 1px solid $hover_color;
    }
    #no-results-message{
        margin-bottom:15px;
    }
}
