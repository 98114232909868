.request-account{
    .header{
        font-size:2.4rem;
        line-height: 3.2rem;
        color:$primary-color;
        padding-top:50px;
        padding-bottom:50px;
        @include tablet{
            font-size:40px;
            line-height: 46px;
        }
    }
    .recaptcha{
        margin-top:30px;
        margin-bottom:30px;
        label{
            margin-bottom:15px;
        }
    }
    .form-submit{
        .button{
            min-width:155px;
        }
    }
}