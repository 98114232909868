.featured-news {
    padding-top: 20px;
    background-color: #fff;

    .news-title {
        text-align: center;
        width: 100%;
        color: $accent_color;
        font-size: 40px;
        line-height: 46px;
        font-weight: 300;
        margin: 50px auto 40px;
    }

    .news-link {
        text-align: center;
        padding: 50px 0;
        clear: both;
    }
}

.news-listing {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include large-tablet {
        justify-content: left;
    }

    &.listing-page {
        margin-bottom: 30px;

        h1 {
            font-size: 2.85rem;
            font-weight: 300;
        }

        .news-list-title {
            font-weight: 700;
        }
    }
    
    &> [class*="col-"]{
        margin-bottom:30px;
    }

    .news-list-item {
        margin: 15px 0;
        max-width: 360px;
        box-shadow: 0 1px 4px 0px rgba(0, 0, 0, .2);
        text-align: center;

        @include large-tablet {
            height: 100%;
        }
    }

    .news-image {
        height: 191px;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    a.news-list-title {
        display: block;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 700;
        color: $primary-color;

        @include states {
            color: $primary-color;
            text-decoration: underline;
        }
    }

    .news-list-content {
        border-left: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        border-right: 1px solid #e2e2e2;
        padding: 20px 12px;
        line-height: 26px;
        white-space: normal;
        text-align: left;

        @include large-tablet {
            position: relative;
            padding-bottom: 72px;
            height: calc(100% - 191px); //full height minus image height
        }
    }

    .news-summary {
        @include at(425) {
            font-size: 16px;
            line-height: 30px;
        }
    }

    .read-more {
        font-weight: 700;
        text-decoration: underline;
        line-height: 1em;

        @include states {
            color: $primary-color;
            text-decoration: none;
        }
    }

    .news-list-date {
        color: $secondary_color;
        letter-spacing: 0.2rem;
        font-size: 12px;
        margin-top: 20px;
        padding-top: 25px;
        border-top: 1px solid $secondary_color;

        @include large-tablet {
            position: absolute;
            bottom: 20px;
            display: block;
            width: calc(100% - 30px);
        }
    }
}