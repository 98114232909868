:root {
  font-size: 16px;
}
html, body {
  margin: 0;
  height: 100%;
}
body {
  background-color: #ffffff;
  color: $body_color;
  font-family: Ubuntu, sans-serif;
  font-size: 1rem;
  transition-duration: .5s;
}

.loading {
  opacity: 0;
}

main {
  background: #e9e9e9;
  background: linear-gradient(to bottom, #E9E9E9 0%, #fff 115px);
  min-height: calc(100vh - 389px);
  @include tablet{
    min-height: calc(100vh - 702px);
  }
}

p,
.p,
article,
.article {
  color: $body_color;
  font-family: Ubuntu, sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  transition-duration: .5s;
}

h1,
.h1 {
  color: $body_color;
  font-family: Ubuntu, sans-serif;
  font-size: 2.85rem;
  font-style: normal;
  font-weight: 300;
  transition-duration: .5s;
}

h2,
.h2 {
  color: $accent_color;
  font-family: Ubuntu, sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  transition-duration: .5s;
}

h3,
.h3 {
  color: #262626;
  font-family: Ubuntu, sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  transition-duration: .5s;
}

h4,
.h4 {
  color: #262626;
  font-family: Ubuntu, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  transition-duration: .5s;
}

h5,
.h5 {
  color: #262626;
  font-family: Ubuntu, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  transition-duration: .5s;
}

h6,
.h6 {
  color: #262626;
  font-family: Ubuntu, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  transition-duration: .5s;
}

a {
  color: $accent_color;
  text-decoration: none;
  transition: .5s ease all;
}

a:hover,
a.hover {
  color: $body_color;
  text-decoration: underline;
}

.button {
  background-color: $accent_color;
  border-radius: .25em;
  color: #ffffff;
  display: inline-block;
  font-size: 15px;
  padding: 10px 20px;
  text-decoration: none;
  transition: .5s ease all;
  text-align: center;
  font-weight: 600;
  border-style: none;
  margin: 10px;

  @include states {
    color: #ffffff;
    background-color: $hover-color;
    text-decoration: none;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.button.light {
  background-color: #fff;
  border: 2px solid #fff;
  color: $accent_color;
}

.button.light:hover {
  color: #fff;
  background-color: $hover-color;
  border: 2px solid $hover-color;
}

.button.light {
  background-color: #fff;
  color: $accent_color;
  font-size: 1.0728rem;
  font-weight: 600;
}

.button.light:hover {
  color: #fff;
  background-color: $hover-color;
}

.button.reverse {
  background-color: $body_color;
  color: #fff;
}

.button.reverse:hover {
  color: #fff;
  background-color: $hover-color;
}

.button.border {
  background-color: #fff;
  color: $body_color;
  border: 2px solid $body_color;
  padding: 0.4rem 1em;
}

.button.border:hover,
.button.border.hover {
  color: #fff;
  background-color: $hover-color;
  border: 2px solid $hover-color;
}

.button.border.reverse {
  background: $body_color;
  color: #fff;
  border: 2px solid #fff;
  padding: calc(1rem - 2px) 0;
  float: right;
}

.button.border.reverse:hover,
.button.border.reverse.hover {
  background: $hover-color;
  color: #fff;
  border: 2px solid $hover-color;
}

.button.border.accent {
  border: 2px solid $accent_color;
  text-decoration: none;
}

.button.border.accent:hover {
  border: 2px solid $hover-color;
  text-decoration: none;
}

.button.inverted {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;

  @include states {
    background-color: rgba(255, 255, 255, .15);
  }
}

.large {
  font-size: 2rem;
}

.small {
  font-size: .8rem;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

li.line-item-seperator {
  border-bottom: 1px solid #303030;
}

.italic {
  font-style: italic;
}

ul.list {
  font-size: 16px;
  line-height: 30px;
  padding-left: 15px;
}

.bar-placeholder-text {
  display: inline-block;
  padding-left: 15px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  color: $primary-color;
}