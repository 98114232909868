.game-details {
    h1 {
        font-size: 2.85rem;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .active[data-filter-id] {
        background-color: $primary-color;
    }

    .game-detail-image .detail-product-highlight {
        text-align: center;

        img {
            width: 100%;
        }
    }

    .right-column {
        clear: both;
    }

    .detail-img-thumbs {
        display: flex;
        flex-direction: row;
        max-height: 53px;
        margin-top: 20px;
        float: left;
        width: 100%;
        justify-content: space-around;

        div {
            height: 53px;
            width: 53px;
            margin-bottom: 8px;
            border: 1px solid $dark-accent-color;
            box-shadow: 0px 3px 6px $dark-accent-color;
            display: flex;
            cursor: pointer;
        }

        img {
            align-self: center;
            margin: 0 auto;
            max-width: 51px;
            max-height: 51px;
        }
    }

    .detail-product-highlight {
        width: 100%;
    }

    .row {
        padding-bottom: 40px;

        .button {
            width: 100%;
            text-align: center;
            white-space: nowrap;
        }
    }

    img .filter-logo{ max-width:100px;}
    .game-detail-description {
        clear: both;
        padding-top: 40px;
    }

    h2,
    .h2 {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        color: $body_color;
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    .more-results-label, .cloned-games-label {
        font-size: 1rem;
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .detail-cloned-games,
    .detail-game-type,
    .detail-cabinet,
    .detail-platform {
        border-bottom: 1px solid $dark-accent-color;
        padding: 0 15px;

        ul {
            padding-left: 0;
            list-style: none;
            display: inline;
        }

        li {
            display: inline-block;
            margin-bottom: 10px;
        }

        li a {
            padding: 1px 13px;
            margin-right: 5px;
            background: $category-bg-color-dark;
            display: block;
            font-size: 1rem;
            border-radius: 21px;
            color: #fff;
            font-weight: 300;
        }

        li a:hover {
            text-decoration: none;
            background-color: $accent_color;
        }

        .active {
            background: $accent_color;
            color: #fff;
        }
    }
}

@media screen and (min-width:768px) {
  .game-details {
    .right-column {
      clear: none;
    }
    .row {
      .button {
        float: right;
        width: auto;
        text-align: left;
      }
    }
    .detail-img-thumbs {
      flex-direction: column;
      max-width: 53px;
      margin-right: 10px;
      max-height: none;
      margin-top: 0;
      width: auto;
    }
    .game-detail-image .detail-product-highlight {
      width: 82%;
      float: right;
    }
    .game-detail-description {
      clear: none;
      padding-top: 0;
    }
  }
}