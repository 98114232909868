.games-filter {
    padding-top:80px;
    .flex-row {
        flex-wrap: wrap;
    }
    ul {
        @include resetUL;
    }
    .wrap {
        width: 100%;
        .flex-row {
            @include large-tablet {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
    .categories-navigation,
    .action,
    .search-all-games {
        @include gutter;
        width: 100%;
    }
    .categories-navigation {
        order: 3;
        @include large-tablet{
            order:1;
        }
        .categories-nav-trigger {
            width: 100%;
            position: relative;
            background-color: #515151;
            text-align: left;
            text-transform: uppercase;
            font-weight: 400;
            border-radius: 0;
            box-shadow: none;
            transition-duration: .5s;
            margin: 10px 0;
            &:after {
                content: "\f107";
                font-family: fontAwesome;
                position: absolute;
                right: 15px;
                top: 50%;
                font-size: 24px;
                transform: translateY(-50%);
                transition-duration: .5s;
            }
            @include large-tablet{
                display: none;
            }
        }
        .category-titles {
            display: none;
            margin-left: -15px;
            margin-right: -15px;
            @include large-tablet{
                display: flex !important;
                max-width: 669px;
                justify-content:space-between;
                align-items: center;
                margin-left:0;
                margin-right:0;
            }
            .accordion {
                a.categories-trigger {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #262626;
                    padding: 10px 15px;
                    margin: 0 15px;
                    text-decoration: none;
                    @include large-tablet{
                        display: inline-block;
                        margin:0;
                        padding-left:0;
                        padding-right:0;
                        font-size:12px;
                        color:#737373;
                    }
                    @include large-tablet{
                        font-size:14px;
                    }
                    @include desktop{
                        font-size:16px;
                    }
                    .caret {
                        transition-duration: .5s;
                    }
                }
                &:not(:last-child) {
                    a {
                        border-bottom: 1px solid #262626;
                        @include large-tablet{
                            border: none;
                        }
                    }
                }
                &.open {
                    .accordion-trigger {
                        color:#262626;
                        border-color: transparent;
                        .caret {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
        }
        &.active {
            .categories-nav-trigger {
                background-color: $primary-color;
                &:after {
                    transform: translateY(-50%) rotate(-180deg);
                }
            }
        }
    }
    .categories-desktop-placeholder {
        order: 4;
        width: 100%;
        background-color: #262626;
       
    }
    .categories {
        background-color: #262626; 
        display: none;
        width: 100%;
        max-width: 1170px;
        margin:0 auto;
        padding-top:15px;
        padding-bottom:15px;
        
       
        &:after {
            clear: both;
            content: " ";
            display: table;
        }
        a {
            color: #fff;
            background-color: #737373;
            border-radius: 50px;
            float: left;
            margin: 5px;
            font-size: 14px;
            padding: 5px 15px;
            transition-duration:.25s;
            &.active{
                background-color:$primary-color;
            }
        }
    }
    .action {
        width: 100%;
        order: 2;
        @include large-tablet{
            max-width: 155px;
            padding:0;
            margin-left:15px;
        }
        .button {
            width: 100%;
            margin: 10px 0;
        }
    }
    .search-all-games {
        order: 1;
        position: relative;
        margin: 10px 0;
        @include large-tablet{
            order:3;
            max-width: 277px;
        }
        input {
            width: 100%;
            font-size: 16px;
            padding: 9px 30px 9px 15px;
            border-radius: 50px;
            border: 1px solid #95989A;
        }
        button {
            position: absolute;
            right: 15px;
            top: 0;
            font-size: 16px;
            padding: 10px;
            color: #000000;
            border-radius: 0 50px 50px 0;
            border: none;
            background-color: transparent;
        } //placeholder #95989A italic
    }
}