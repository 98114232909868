
.bounce-alert{
    animation-name: bounceAlert;
}
@keyframes bounceAlert {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}