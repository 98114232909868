.banner-landing {
    .banner {
        min-height: 700px;
        padding-bottom: 45px;
        box-sizing: border-box;

        &.banner-short {
            min-height: 0;
            height: 450px;
        }
    }

    .content,
    .content .container,
    .content .container .flex {
        height: 100%;
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include theme(".sfPageEditor") {
            display: block;
        }
    }

    .header,
    .page-description {
        width: 100%;
    }

    .page-description {
        align-self: flex-end;
        max-width: 845px;
    }

    .breadcrumb-wrapper {
        padding-top: 30px;
    }

    .crumb li {

        a,
        span {
            color: #fff;

            @include theme(".sfPageEditor") {
                color: inherit;
            }
        }
    }


    .h1,
    h1,
    .h2,
    h2 {
        color: #fff;
        font-size: 40px;

        @include theme(".sfPageEditor") {
            color: inherit;
        }
    }

    h2,
    .h2 {
        font-size: 23px;
        margin-bottom: 5px;
        text-shadow: 0 0 3px rgba(0, 0, 0, .16);
    }

    p {
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;

        @include theme(".sfPageEditor") {
            color: inherit;
        }
    }

    .button {
        margin-left: 0;
    }
}