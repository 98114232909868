﻿.paging {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding-bottom: 100px;
    align-items: baseline;
    border-top: 1px solid $body_color;

    ul.pagination {
        list-style: none;
        padding-left: 0;
        padding-top: 35px;

        li {
            display: inline-block;
            align-self: flex-start;
        }

        a {
            color: $body_color !important;
            padding: 8px 13px;
            margin: auto 3px;
            display: block;
            transition: 0.5s ease all;
            border: 0px;
            border-radius: 50%;
        }

        a:hover {
            color: $accent_color;
        }

        a.paging-current, .active a {
            background-color: $accent_color !important;
            border-radius: 50%;
            color: #fff !important;
        }

        a.paging-current:hover, .active a:hover {
            color: #fff;
            text-decoration: none;
        }
    }

    .paging-first, .paging-previous, .paging-next, .paging-last {
        margin: 0 15px;
        color: $body-color !important;

        &:hover {
            color: $accent_color !important;
        }
    }

    a.paging-first:before {
        content: "\f060";
        display: inline-block;
        margin-right: 5px;
        font-family: "FontAwesome";
    }

    a.paging-last:after {
        content: "\f061";
        display: inline-block;
        margin-left: 5px;
        font-family: "FontAwesome";
    }
}
