.cta-contact-us.grid {
    background-color: $primary-color;

    .container {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 70px;
    }

    .h2,
    h2,
    .h3,
    h3 {
        font-size: 40px;
        line-height: 46px;
        font-weight: 300;
    }

}

.line-accent:before, .line-accent:after {
    display: inline-block;
    content: "";
    border-top: 1px solid #000;
    width: 75px;
    margin: 0 15px;
    vertical-align: middle;
}