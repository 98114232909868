.cabinets-landing-page {
    .banner-overlay-content-bottom {
        &.paragraph {
            bottom: 17%;
        }

        i {
            position: initial;
            background: rgba(0,0,0,0.8);
        }
    }
   
}
