﻿.banner {
    position: relative;
    background-position: center;
    background-size: cover;
    height: 550px;


    @include tablet {
        height: 340px;
    }

    @include at("992") {
        height: 580px;
    }

    @include at("1200") {
        height: 490px;
    }

    @include desktop {
        height: 588px;
    }

    @include at("1740") {
        height: 675px;
    }

    @include at("2000") {
        height: 680px;
    }

    @include theme(".sfPageEditor") {
        height: auto;
    }


    .banner-overlay-content {
        p {
            @include at("600") {
                max-width: 60%;
                display: inline-block;
            }

            @include tablet {
                max-width: unset;
                display: block;
            }
        }

        @include tablet {
            margin-top: -10px;
            margin-bottom: 55px;
        }

        @include at("1200") {
            margin-top: 45px;
            margin-bottom: 0;
        }

        @include theme(".sfPageEditor") {
            margin-top: 0;
        }
    }

    .banner-overlay-image {
        text-align: center;

        @include tablet {
            text-align: inherit;
        }
    }

    .banner-content-wrapper {

        @include tablet {
            display: inline-block;
            max-width: 768px;
            margin: 0 auto;
            width: 100%;
            height: 100%;
        }

        @include at("992") {
            max-width: 992px;
        }
    }

    .banner-content {
        color: #fff;
        padding: 3px 20px 15px;
        height: 100%;
        position: absolute;
        width: 100%;
        left: 0;

        h1,
        .h1 {
            line-height: 1.25em;
            color: #fff;
            font-size: 1.75rem;
            font-weight: 300;
            display: block;
            margin: 15px 0;

            @include at("992") {
                font-size: 2.8571rem;
            }
        }

        p {
            color: #fff;
            font-weight: 300;
            font-size: 1.1429rem;
            line-height: 1.5em;
        }

        .button {
            padding: 1em 2.5em;
            margin: 1em 1em 1em 0;
        }

        .home-page-video {
            border: 1px solid grey;
            box-shadow: 0 6px 26px 2px rgba(0, 0, 0, 0.6);
        }

        i {
            position: absolute;
            top: 37%;
            left: 46%;
            color: white;
            background: rgba(90, 90, 90, .6);
            padding: 12px;
            border-radius: 9001px;
            border: 2px solid white;
        }

        h1,
        .h1 {

            @include tablet {
                font-size: 1.8571rem;
                text-align: left;
                margin-bottom: 30px;
                display: block;
            }
        }

        .btn {

            @include at("992") {
                margin-top: 40px;
            }
        }

        @include tablet {
            left: 0;
            height: auto;
            top: 0;
        }
    }

    .background-container {
        @include theme(".sfPageEditor") {
            top: 40%;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
    }

    &.home {
        a.button {
            @include at("600") {
                vertical-align: top;
                text-align: right;
                float: right;
            }

            @include tablet {
                vertical-align: initial;
                text-align: initial;
                float: initial;
                box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.3);
            }
        }

        .banner-content {
            max-width: none;
            padding: 15px;
            height: auto;
            left: auto;
            bottom: auto;
            position: absolute;
            top: 0;


            h1 {

                @include tablet {
                    position: relative;
                    top: 0;
                    text-align: center;
                    left: 0;
                    margin: 0 auto 5px;
                    width: 100%;
                    max-width: none;
                }
            }

            h2 {

                @include tablet {
                    font-size: 1.4286rem;
                }
            }

            i {
                @include at("1200") {
                    padding: 25px;
                }
            }

            @include tablet {
                top: auto;
                bottom: 0;
                position: absolute;
            }

            @include at("992") {
                top: 25%;
                height: auto;
            }

            @include theme(".sfPageEditor") {
                top: 0;
            }
        }
    }
}

.banner-gradient {
  background: -moz-linear-gradient(top, rgba(132, 0, 0, 1) 0%, rgba(132, 0, 0, 0.91) 34%, rgba(104, 0, 0, 0.81) 74%, rgba(28, 0, 0, 0.74) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(132, 0, 0, 1) 0%, rgba(132, 0, 0, 0.91) 34%, rgba(104, 0, 0, 0.81) 74%, rgba(28, 0, 0, 0.74) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(132, 0, 0, 1) 0%, rgba(132, 0, 0, 0.91) 34%, rgba(104, 0, 0, 0.81) 74%, rgba(28, 0, 0, 0.74) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#840000', endColorstr='#bd1c0000', GradientType=0);
  /* IE6-9 */
  height: 100%;
}