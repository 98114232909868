.news-detail {
  
  h1 {
    margin-bottom:50px;
  }
  
  .news-detail-date {
    font-weight: bold;
    border-bottom:1px solid $body_color;
    margin-bottom:25px;
    padding-left:0;
  }
  
  .news-detail-image {
    margin-bottom:25px;
    
    img {
      width:100%;
    }
    
  }
  p {
    line-height: 2rem;
  }
  .news-detail-footer {
    font-style: italic;
    margin:50px auto;
    text-align: center;
  }
}