﻿header {
    .container-fluid {
        // max-width: 1720px;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .flex-row {
        flex-wrap: wrap;

        @include laptop {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    .navigation,
    .login,
    .search,
    .nav-header {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        display: none;

        @include laptop {
            width: auto;
            display: flex !important;
        }
    }

    .nav-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        // width: 140px;
        padding-top: 25px;
        padding-bottom: 25px;
        box-sizing: border-box;

        @include laptop {
            order: 1;
        }

        @include desktop {
            width: 225px;
        }
        img {
            max-width: none;
        }
    }

    .nav-trigger {
        @include laptop {
            display: none;
        }

        .open-view {
            display: none;

            span:first-child {
                margin-right: 10px;
            }
        }

        .closed-view {
            display: inline;
        }

        &.open {
            .open-view {
                display: block;
            }

            .closed-view {
                display: none;
            }
        }

        .button {
            margin: 0;
            font-family: 'Ubuntu', sans-serif;
            padding: 10px 18px;
            font-size: 16px;
            line-height: 18px;
            color: $primary-color;
        }
    }

    .navigation {
        background-color: #000;
        padding: 35px 50px 15px 40px;

        @include laptop {
            order: 2;
            background-color: transparent;
            padding: 0;
            width: 100%;
            max-width: 992px;
        }

        .accordion-content {
            display: none;

            @include laptop {
                display: block !important;
                height: auto !important;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            @include laptop {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 25px;
                margin-right: 25px;
                width: 100%;
            }

            & > li {
                position: relative;
            }
        }

        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            padding: 15px 0;
            font-family: "Ubuntu", sans-serif;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;

            @include laptop {
                color: #262626;
                font-size: 14px;
                padding: 27px 0;
                transition-duration: .25s;

                @include states {
                    color: $primary-color;
                }
            }

            @include desktop {
                font-size: 16px;
                padding: 34px 0;
            }

            .caret {
                display: block;
                width: 25px;
                height: 25px;
                position: relative;
                background-color: $primary-color;
                border: none;
                transition-duration: .5s;

                &:after {
                    content: "\f107";
                    font-family: fontAwesome;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: #fff;
                }

                @include laptop {
                    display: none;
                }
            }
        }

        .open {
            .caret {
                transform: rotate(-180deg);
                background-color: rgba(#b60014, .53);
            }
        }
    }

    ul.sub-nav,
    .sub-nav {
        @include laptop {
            position: absolute;
            display: block;
            background-color: #fff;
            z-index: 1;
            margin: 0;
            width: auto;
            @include hidden;

            &.active {
                @include visible;
            }
        }

        a {
            padding: 10px 20px;
            font-weight: 300;
            display: block;
            white-space: nowrap;
        }
    }

    .search-login {
        order: 3;
        width: 100%;

        @include laptop {
            width: auto;
            display: flex;
            align-items: center;
        }
    }

    .search {
        background-color: #000;
        position: relative;
        padding: 0 35px;

        @include laptop {
            order: 4;
            background-color: transparent;
            align-items: center;
            font-size: 18px;
            height: 100%;
        }

        @include theme(".sfPageEditor") {
            width: 150px;
        }

        .search-trigger {
            display: none;

            @include laptop {
                display: block;
                padding: 10px;

                @include states {
                    color: $primary-color;
                    text-decoration: none;
                }
            }

            &.fa-times {
                position: absolute;
                top: 25px;
                right: 50px;
                color: #fff;
                font-size: 24px;

                &:hover {
                    color: $primary-color;
                }
            }
        }

        .input-wrapper {
            display: flex;
            border-bottom: 1px solid #95989A;

            @include laptop {
                position: fixed;
                top: 0 !important;
                left: 0;
                z-index: 1001 !important;
                height: 100%;
                background-color: rgba(0,0,0, .95);
                width: 100%;
                padding: 30px;
                padding-top: 100px;
                box-sizing: border-box;
                border: none;
                @include hidden;
            }

            &.active {
                @include visible;
            }
        }

        .search-box-container {
            @include laptop {
                max-width: 900px;
                position: absolute;
                margin: 0 auto;
                left: 0;
                right: 0;
            }
        }

        .cta-container {
            display: none;

            @include laptop {
                display: block;
                color: #fff;
                margin-top: 50px;
                font-weight: bold;
                text-align: center;
                padding: 0 75px;
            }


            &:first-of-type {
                @include laptop {
                    border-right: 1px solid #95989A;
                }
            }

            .button {
                font-size: 12px;
                //margin-top:15px;
            }
        }

        input[type="search"] {
            width: 90%;
            line-height: 26px;
            font-weight: 300;
            background-color: transparent;
            font-family: "Ubuntu", sans-serif;
            border: none;
            padding: 8px;
            color: #fff;

            @include laptop {
                width: 95%;
                border-bottom: 1px solid #333;
                padding: 8px;
                font-size: 30px;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: #fff;
            padding-left: 15px;
            padding-right: 15px;
            position: absolute;
            right: 27px;
            font-size:16px;
            top:10px;

            @include laptop {
                right: 50px;
                top: 4px;
                font-size: 26px;
            }

            &:hover {
                color: $primary-color;
            }
        }
    }

    .login {
        background-color: #000;
        position: relative;

        @include laptop {
            order: 3;
            background-color: transparent;
        }

        @include theme(".sfPageEditor") {
            width: 200px;
        }

        a.login-trigger {
            display: none;

            @include laptop {
                display: block;
                font-size: 15px;
            }

            @include desktop {
                display: none;
            }
        }

        .header-logged-out,
        .header-logged-in {
            @include laptop {
                position: absolute;
                width: 330px;
                padding: 15px;
                right: 30px;
                background-color: #fff;
                box-sizing: border-box;
                @include hidden;
            }

            @include desktop {
                position: relative;
                padding: 6px 15px;
                box-shadow: none;
                right: 0;
                background-color: transparent;
                @include visible;
                top: 0;
            }

            &.active {
                @include visible;

                @include desktop {
                    top: 0;
                }
            }
        }

        .header-logged-in {
            display: none;
            background-color: #000;
            width: 100%;

            @include laptop {
                background-color: #fff;
                width: 221px;
            }

            .welcome-message {
                color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;
                line-height: 17px;
                font-weight: 700;
                padding: 30px 15px;

                @include states {
                    color: #fff;
                    text-decoration: none;
                }

                @include laptop {
                    padding: 0;
                    color: $primary-color;

                    @include states {
                        color: $primary-color;
                    }
                }

                @include desktop {
                    padding: 30px 15px;
                }

                .fa-angle-down {
                    font-weight: 700;
                    transition-duration: .25s;

                    @include laptop {
                        display: none;
                    }

                    @include desktop {
                        display: block;
                    }
                }
            }

            .accordion-content {
                display: none;

                @include laptop {
                    display: block !important;
                    height: auto !important;
                    position: relative;
                    box-shadow: none;
                    @include visible;
                }

                @include desktop {
                    position: absolute;
                    @include hidden;
                }
            }

            .sub-nav {
                @include desktop {
                    width: 231px;
                    right: 0;

                    &.active {
                        @include visible;
                    }
                }
            }

            .user-navigation {
                @include laptop {
                    padding: 0;
                }

                @include desktop {
                    padding: 15px;
                    box-sizing: border-box;
                }

                li > a {
                    color: #fff;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 55px;

                    @include laptop {
                        color: #303030;
                        padding: 0;

                        @include states {
                            color: #303030;
                        }
                    }

                    @include desktop {
                        @include gutter;
                    }
                }
            }

            &.open {
                .welcome-message {
                    .fa-angle-down {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        .flex-row {
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap
        }

        .button-wrapper {
            @include gutter;
        }

        .button,
        .button.border {
            width: 50%;
            font-size: 12px;
            padding: 10px;

            @include phone {
                font-size: 15px;
            }

            @include laptop {
                font-size: 12px;
                padding: 10px 17px;
                width: auto;
                margin: 0;
            }

            @include desktop {
                padding: 10px 20px;
            }
        }

        .button.border {
            color: #fff;
            border-color: #fff;
            background-color: transparent;

            @include laptop {
                color: $body_color;
                border: 2px solid $body_color;
            }
        }
    }
}
    //since these styles will apply across all autocomplete results, including the resource library search, these styles might need to live in the widget view
    ul.ui-autocomplete {
        background-color: #000;
        display: block !important;
        padding: 0;
        max-width: 100%;
        margin-left: -30px;
        margin-top: 30px;

        @include tablet {
            max-width: 255px;
        }

        li {
            list-style: none;
            padding: 15px;
            cursor: pointer;

            a {
                color: #fff;
                cursor: pointer;
            }
        }
    }
