.team-details.grid {
  padding-bottom: 250px;
  background-color: transparent;

  .flex-row.member-name {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 75px;
  }

  h1,
  .h1 {
    margin-top: 0;
  }

  h2,
  .h2 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 23px;
    line-height: 26px;
    color: $primary-color;
  }

  img {
    width: 100%;
    margin-bottom: 30px;
  }

}

.team-details-breadcrumb.container {
  padding-top: 75px;
}