.cabinets-listing {
    .h3 {
        text-align: center;
        font-weight: 700;
        width: 100%;
        height: 25px;
        border-bottom: 1px solid black;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px;

        span {
            font-size: 1.7rem;
            padding: 0 10px;
            background-color: $light_bg_color;
        }
    }
}
