.age-verification {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-image: url("/img/Konami Stock Art/age-verif-bkg.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 9999;
    color: #fff;
    p {
        color: #fff;
    }
    &:before {
        @include overlay;
        background-color: rgba(0, 0, 0, .95);
    }
    .logo {
        padding: 66px 15px 0;
        position: relative;
        text-align: center;
        margin: 0 auto;
    }
    .content {
        max-width: 495px;
        margin: 0 auto;
        padding: 15px 10px;
        box-sizing: border-box;
        position: relative;
        text-align: center;
    }
    .legal-warning {
        color: #fff;
        margin: 0 auto;
        font-weight: 700;
        line-height: 24px;
        font-size: 16px;
    }
    .age-country,
    .age-birthdate {
        max-width: 420px;
        margin: 30px auto;
    }
    .age-birthdate {
        display: flex;
        justify-content: center;
        align-items: center;
        input {
            width: calc(128px * .65);
            height: calc(114px * .65);
            border: 1px solid $secondary_color;
            margin: 0 7px;
            background: $body_color;
            color: #fff;
            font-size: 1.5rem;
            text-align: center;
            @include tablet {
                width: calc(128px * .75);
                height: calc(114px * .75);
            }
            @include laptop {
                width: 128px;
                height: 114px;
            }
        }
        &.invalid {
            input {
                border-color: lighten($primary-color, 20);
            }
        }
        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        input[type=number] {
            -moz-appearance:textfield;
        }
    }
    label {
        font-weight: 300;
    }
    p.cookie-message {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: #fff;
        margin: 30px auto 15px;
        max-width: 400px;
        a {
            text-decoration: none;
            border-bottom: 1px solid white;
            padding-bottom: 2px;
            color: #fff;
        }
        a:hover {
            color: $hover-color;
        }
    }
    .remember-me {
        display: flex;
        justify-content: flex-start;
        align-self: center;
        max-width: 215px;
        margin: 0 auto;
        color: #fff;
        font-size: 23px;
        font-weight: 700;
        cursor: pointer;
        input[type=checkbox] {
            display: none
        }
    }
    .remember-me-warning {
        p {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
        }
        &:after {
            display: block;
            content: "";
            width: 93.5px;
            height: 1px;
            margin: 0 auto;
            background-color: #95989A;
        }
    }
    .button-row {
        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .button {
        display: block;
        max-width: 250px;
        padding: 14px 42px;
        font-size: 15px;
        line-height: 1em;
        margin: 15px auto;
    }
    .cta {
        padding-top: 20px;
        .click-prompt {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 5px;
        }
        .brand-name {
            font-weight: 700;
            font-size: 23px;
            line-height: 26px;
        }
        .image {
            margin-top: 20px;
        }
    }
}

.age-country .select-icon {
    position: absolute;
    top: .8em;
    right: 1em;
    color: $secondary_color;
}

.double-bar-separator {
    max-width: 60px;
    margin: 35px auto 15px;
    border-top: 1px solid $dark-accent-color;
    border-bottom: 1px solid $dark-accent-color;
    height: 10px;
}