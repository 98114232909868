.customer-assignment {
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    max-width: 768px;
    padding: 50px;
    margin: 50px auto;
    font-family: "Ubuntu", sans-serif;
    height: 554px;

    &.loading {
        opacity: 1;
        height: 554px;
        .listbox-wrapper, .select-sales, .save-button {
            opacity: 0;
        }

        .list-loader {
            display: block;
        }
    }

    .list-loader {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        color: rgba($primary-color, .5);
    }

    .customer-list-label{
        margin-right:30px;        
    }

    .listbox-wrapper,
    .select-sales,
    .save-button {
        opacity: 1;
        transition-duration: .75s;
    }

    .select-sales {
        margin: 15px 0;
        border-color: #eee;
        cursor: pointer;
    }

    .save-button {
        margin-left: 0;

        &.loading {
            .save-text {
                display: none;
            }

            .save-loader {
                display: inline-block;
            }
        }

        .save-text {
            display: inline-block;
        }

        .save-loader {
            display: none;
        }
    }

    .k-listbox.k-widget {
        width: calc(50% - 19px);
        margin: 15px auto;
        height: 250x;

        .k-list-scroller {
            border: 1px solid #eee;
        }

        &:first-of-type {
            width: calc(50% + 15px);
        }
    }

    .k-item {
        transition-duration: .3s;

        &:hover,
        &.k-state-selected {
            background-color: #ebebeb;
            cursor: pointer;
        }
    }

    .k-button {
        border-radius: 2px;
        border-color: #fafafa;
        color: #444;
        background-color: #fafafa;
        background-position: 50% 50%;
        box-shadow: 0 2px 6px rgba(0,0,0,.2), 0 2px 3px rgba(0,0,0,.05);
        transition-duration: .3s;

        &.k-state-disabled {
            cursor: pointer !important;
        }

        &:hover {
            background-color: #ebebeb;
            border-color: #ebebeb;
        }
    }
}
