.grid {
    background-color: #F2F2F2;
    color: #303030;

    .flex-row {
        flex-wrap: wrap;
    }

    .background-setter {
        padding-top: 75px;
        padding-bottom: 75px;
        box-sizing: border-box;

        @include tablet {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        &.dark {
            @include theme(".sfPageEditor") {
                color: inherit;
            }
        }
    }

    .dark {
        color: #fff;

        .h2,
        .h3,
        h2,
        h3,
        p {
            color: #fff;
        }
    }

    .col-30,
    .col-40,
    .col-60,
    .col-70,
    .col-50,
    .col-100 {
        @include gutter;
        width: 100%;
        order: 2;
    }

    .col-30 {
        @include tablet {
            width: 30%;
        }
    }

    .col-40 {
        @include tablet {
            width: 40%;
        }
    }

    .col-50 {
        @include tablet {
            width: 50%;
        }
    }

    .col-60 {
        @include tablet {
            width: 60%;
        }
    }
    .col-70 {
        @include tablet {
            width: 70%;
        }
    }

    .first-on-mobile {
        order: 1;

        @include tablet {
            order: 2;
        }
    }

    .h2,
    .h3,
    h2,
    h3 {
        font-size: 26px;
        font-weight: 700;
        margin-top:20px;
        margin-bottom: 10px;
    }

    p,
    .list {
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
        margin-bottom: 20px;
    }
}