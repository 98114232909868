﻿.background-setter {
    min-height: 50px;
    position: relative;

    &:before {
        background-color: inherit;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
    }

    .content {
        position: relative;
    }
}