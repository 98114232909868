@mixin inputBoxes() {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea,
    select {
        @content
    }
}

.custom-checkbox {
    display: block;
    height: 25px;
    width: 25px;
    border: 1px solid #FFFFFF;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    margin: 0 10px;
    position: relative;
    cursor: pointer;
}

input[type=checkbox]:checked+.custom-checkbox:after {
    content: "\f00c";
    font-family: fontAwesome;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.enewsletter {
    margin-left: 22px;
    position: relative;

    input[type=checkbox] {
        display: inline-block;
        height: 16px;
        width: 16px;
        position: absolute;
        left: -20px;
        top: -3px;
    }
}

.kg-form {
    margin: 50px auto 100px;
    width: 100%;

    label {
        position: relative;
        display: block;
        width: 100%;
        color: rgba(#090909, .7);
        font-size: 16px;
        z-index: 0;

        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition-duration: .25s;
            z-index: -1;

            &.textarea-label {
                top: 8%;
                transform: none;
                left: 15px;
            }
        }

        @include inputBoxes() {
            &:active + span,
            &:focus + span,
            &.has-value + span {
                top: -18px;
                left: 0;
                font-size: 12px;
                transform: none;
                color: rgba(#090909, .6);
            }
        }
    }

    @include inputBoxes() {
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid #95989A;
        background-color: transparent;
        padding: 5px 10px 10px;
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
        transition-duration: .25s;
        color: rgba(#090909, .8);
        height: auto;
        border-radius: 0;
        box-shadow: none;
    }

    textarea {
        border: 1px solid #95989A;
    }

    select {
        color: transparent;

        &:active,
        &:focus,
        &.has-value {
            color: rgba(#090909, .8);
        }
    }

    .button {
        padding: 10px 40px;
    }

    .recaptcha img, .recaptcha a{
        margin-bottom:10px; 
    }
}