.selected-categories {
    padding:15px;
    margin: 0 auto;
    width:100%;
    @include tablet{
        width:auto;
    }
    .container {
        max-width:1110px;
        border-bottom: 1px solid #262626;
    }
    .flex-row {
        flex-wrap: wrap;
        @include tablet{
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: center;
        }
    }
    ul {
        @include resetUL;
    }
    .current-selected {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width:100%;
        .title {
            font-size: 12px;
            color: #262626;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-right: 10px;
            width:100%;
            @include tablet{
                width: auto;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            width:100%;
            @include tablet{
                width: auto;
            }
            li {
                margin: 5px;
                span {
                    display: flex;
                    align-items: center;
                    padding: 3px 7px;
                    background-color: #515151;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 13px;
                    a {
                        color: #fff;
                        padding: 0 0 0 10px;
                    }
                }
            }
        }
    }
    .clear-all-selected {
        padding:15px 0 20px;
        width:100%;
        @include tablet{
            width:auto;
            white-space: nowrap;
        }
        a {
            text-decoration: none;
            position: relative;
            &:after {
                content: "";
                display: block;
                position: absolute;
                background-color: #95989A;
                height: 1px;
                width: 100%;
                bottom: -5px;
            }
        }
    }
}