.video-gallery-banner {
    .banner {
        height: auto;
    }

    .content {
        @include large-tablet {
            padding: 45px 0;
        }
    }

    .breadcrumb-wrapper {
        background-color: transparent;
        padding-top: 30px;

        .crumb li {

            a,
            span {
                color: #fff;
                font-size: 14px;
            }
        }
    }

    .h1 {
        color: #fff;
    }
}