/*-------MEDIA QUERIES-------*/

@mixin phone() {
    @media only screen and (min-width: 375px) {
        @content;
    }
}

@mixin tablet() {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin large-tablet() {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin laptop() {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin desktop() {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}

@mixin at($size) {
    @media only screen and (min-width: $size + "px") {
        @content;
    }
}

/*-------A-TAG STATES-------*/

@mixin states {
    &:hover,
    &:active,
    &:focus {
        @content;
    }
}

/*-------THEMES-------*/

@mixin theme($theme) {
    $result: '';
    @each $item in & {
        $result: $result $theme $item ',';
    }
    @at-root #{$result} {
        @content;
    }
}

/*-------CUTSOM STYLES-------*/

@mixin hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -100;
    transition-duration: .5s;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, .28);
    top: 150%;
}

@mixin visible {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
    top: calc(100% + 20px);
}

@mixin overlay {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@mixin gutter {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}

@mixin resetUL{
    margin:0;
    padding:0;
    list-style:none;
}