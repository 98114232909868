.crumb {
    padding-top: 10px;

    ul {
        padding-left: 0;
        list-style: none;
    }

    li {
        display: inline-block;

        a, span {
            text-transform: uppercase;
            color: $accent_color;
            font-weight: 300;
            font-size: 1rem;
            vertical-align:middle;
        }

        span {
            color: $accent_color;
            margin: 0 5px;
        }

        &:after {
            content: "\00b7";
            font-size:18px;
            vertical-align:middle;
        }

        &:last-of-type:after {
            content: "";
        }
    }
}
