.careers {
    h2 {
        font-size: 1.8rem;
        font-weight: 600;
        padding-bottom: 25px;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 600;
    }

    p {
        font-size: 1.06rem;
        line-height: 2.3rem;
    }

    .job-openings-banner {
        background-color: $primary-color;
        margin: 30px 15px;

        p {
            color: white;
            font-size: 1.8rem;
            font-weight: 700;
            text-align: center;
            margin-bottom: 40px;

            &.bg-line {
                font-weight: 700;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid black;
                color: white;
                margin-top: 30px;
                height: 19px;
                margin-bottom: 25px;

                a {
                    color: white;

                    span {
                        font-size: 1.3rem;
                        padding: 0px 25px;
                        background-color: $primary-color;
                    }
                }
            }
        }
    }

    .extra-top-padding {
        padding-top: 30px;
    }

    .extra-bottom-padding {
        padding-bottom: 80px;
    }

    .temp-video {
        background-color: $secondary-color;
        min-height: 260px;
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,.1);

        i {
            position: absolute;
            top: 37%;
            left: 44%;
            color: white;
            padding: 25px;
            border-radius: 9001px;
            border: 2px solid white;
        }
    }

    ul {
        padding-bottom: 35px;
        padding-left: 15px;

        li {
            padding-bottom: 10px;
            font-size: 1.07rem;
        }
    }
}
