.dashboard-header {
  padding-bottom: 25px;

  .profile-info {

    h1,
    .h1 {
      font-size: 2.85rem;
      color: $body_color;
      display: inline-block;
    }

    a {
      display: inline-block;
      text-decoration: underline;
      margin-left: 25px;
    }

    @include large-tablet{
      padding-bottom: 100px;
    }
  }

  .contact-support {

    .game-support-header {
      font-weight: 700;

    }
    .game-support-info {

      a {
        text-decoration: underline;
      }
    }
  }

  .external-links {
    @include large-tablet{
      text-align: right;
    }
  }
}

.sf-profile{
    .button{
        margin:10px 0;
    }
    margin-bottom:30px;
}

.employee-buttons {
  border-top: 1px solid $body_color;
  margin-top: 25px;


  .button.xlarge {
    width: 100%;
    font-size: 2rem;
    text-align: center;
    font-weight: 100;
    padding: 50px 1rem;
    @include large-tablet{
      font-size: 3rem;
    }
    
    i, .i {
      font-size: 4rem;
      margin-right: 10px;
      @include large-tablet{
        font-size: 5rem;
      }
    }
  }
}


.my-games,
.my-cabinets,
.my-platforms {
  padding: 40px 0;

  h2,
  .h2 {
    font-size: 1.64rem;
    color: $body_color;
    margin-top: 0;
  }

  a.see-all {
    padding: 1px 13px;
    margin-right: 5px;
    background: $category-bg-color;
    display: block;
    font-size: 1rem;
    border-radius: 21px;
    color: #fff;
    font-weight: 300;
    text-align: center;
    margin-top: 5px;
    max-width: 100px;
  }
  a.see-all:hover {
    background-color: $accent_color;

  }

}