.resource-library {
  position: relative;


  .search-wrapper {
    width: 100%;

    &.init {
      @include large-tablet {
        position: absolute;
        max-width: 1170px;
        bottom:258px;
        z-index: 1000;

        @include theme(".sfPageEditor") {
          position: relative;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: -89px;
          width: 100%;
          height: calc(100% + 89px);
          background-color: rgba(255, 255, 255, .75);
          z-index: 100;
        }
      }

      .resource-library-search {
        margin-bottom: 30px;

        @include large-tablet {
          position: absolute;
          margin-top: 0;
          margin-bottom: 0;
          left: 50%;
          top: 30%;
          border-radius: 22px;
          transform: translateX(-50%);
          z-index: 1000;
        }
      }

      .mock {
        display: none;

        @include large-tablet {
          display: flex;
        }
      }

      .resource-library-search {
        @include large-tablet {
          max-width: 87.8%;
        }
      }

      .resource-library-search input[type=search] {
        @include large-tablet {

          font-size: 28px;
        }
      }
    }
  }

  .mock {
    display: none;
    align-items: center;
    height: 91px;
    position: relative;

    &:nth-child(odd) {
      background-color: #dedede;
    }


    div {
      border-radius: 3px;
      height: 23px;
      margin-left: 15px;
      background-color: #C4C4C4;
    }

    .mock-image {
      width: 35px;
      height: 45px;
      background-color: #737373;
    }

    .mock-filename {
      width: calc(22% - 65px);
    }

    .mock-product {
      width: calc(14.4% - 15px);
    }

    .mock-type {
      width: calc(7.6% - 15px);
    }

    .mock-region {
      width: calc(21.3% - 15px);
    }

    .mock-updated {
      width: calc(11.5% - 15px);
    }

    .mock-button {
      height: 45px;
      width: calc(10.9% - 15px);
      background-color: #737373;
    }
  }

  .resource-library-search {
    position: relative;
    margin-top: 15px;
    width: 100%;
    max-width: 550px;
  }

  .resource-library-search button {
    border: none;
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 18px;
    transform: translateY(-50%);
    background: transparent;
    color: $secondary-color;
  }

  .resource-library-search input[type=search] {
    border: 1px solid $secondary-color;
    padding: 11px 40px 11px 15px;
    transition: 0.5s ease all;
    border-radius: 25px;
    width: 100%;
    font-size: 16px;

  }

  .resource-library-search input[type=search]::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 300;
  }

  .resource-library-search input[type=search]:-moz-placeholder {
    font-style: italic;
    font-weight: 300;
  }

  .resource-library-search input[type=search]::-moz-placeholder {
    font-style: italic;
    font-weight: 300;
  }

  .resource-library-search input[type=search]:-ms-input-placeholder {
    font-style: italic;
    font-weight: 300;
  }

  .resource-library-search input[type=search]:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt $accent_color;
  }


  .resource-center {
    background: transparent;

  }

}

.resource-search-results {

  h2,
  .h2 {
    font-size: 1.64rem;
    color: $body_color;

    span {
      font-weight: 700;
    }
  }
}